import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    h4: {
      fontSize: '1.75rem',
    },
  },
});

export default theme;
