import { useEffect, useContext } from 'react';
import React from 'react';
import * as Cesium from 'cesium';

import { CesiumViewerContext } from './CesiumViewerProvider';
import { hexToRgb } from '@mui/material';

export default function CesiumCamera({ track, type }) {
  const viewer = useContext(CesiumViewerContext);

  const trackEntityRef = React.useRef(
    new Cesium.Entity({
      position: new Cesium.Cartesian3(0, 0, 0),
      point: {
        pixelSize: 0.1,
        color: Cesium.Color.GREEN.withAlpha(0.001),
        outlineWidth: 0,
      },
      isShowing: false,
    })
  );

  useEffect(() => {
    const entity = trackEntityRef.current;

    if (viewer && entity) {
      viewer.entities.add(entity);
    }

    return () => {
      if (viewer && entity) {
        viewer.entities.remove(entity);
      }
    };
  }, [viewer, trackEntityRef]);

  useEffect(() => {
    if (track && viewer && track.center) {
      trackEntityRef.current.position = track.center;

      const cart = Cesium.Cartographic.fromCartesian(track.center);
      if (cart) {
        trackEntityRef.current.height = cart.height;
      }
    }
  }, [viewer, track, trackEntityRef]);

  const { x: tx, y: ty, z: tz } = track.center;
  const positionDefined = tx !== 0.0 && ty !== 0.0 && tz !== 0.0;
  const trackedEntityNotSet =
    viewer && viewer.trackedEntity !== trackEntityRef.current;
  const cameraInTransition = React.useRef(false);
  useEffect(() => {
    if (trackedEntityNotSet && positionDefined && !cameraInTransition.current) {
      cameraInTransition.current = true;

      viewer.camera.flyToBoundingSphere(track, {
        duration: 2.0,
        complete: () => {
          viewer.trackedEntity = trackEntityRef.current;
        },
      });
    }
  }, [positionDefined, trackedEntityNotSet, viewer, track]);

  const cameraTypeRef = React.useRef(type);
  useEffect(() => {
    if (cameraTypeRef.current !== type) {
      cameraTypeRef.current = type;

      const hpr = new Cesium.HeadingPitchRange(
        viewer.camera.heading,
        type === 'topdown' ? -Math.PI / 2 : -Math.PI / 4,
        type === 'topdown' ? track.radius * 4 : track.radius * 6
      );

      viewer.camera.lookAt(track.center, hpr);
    }
  }, [viewer, track, type, positionDefined]);

  useEffect(() => {
    if (track && viewer && track.center) {
      const minD = track.radius * 3.0;
      const targetD = track.radius * 6.0;
      const currentD = viewer.camera.distanceToBoundingSphere(track);

      if (currentD < minD) {
        const adjust = targetD - currentD;
        viewer.camera.moveBackward(adjust);
      }
    }
  }, [viewer, track]);

  return <></>;
}
