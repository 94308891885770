import { localSync, wsURL } from '../config';

class EventSync {
  constructor(latched = false) {
    this.listeners = [];
    this.latched = latched;
  }

  subscribe(callback) {
    this.listeners.push(callback);

    if (this.latched && this.last) {
      callback(this.last);
    }
  }

  emit(msg) {
    if (this.latched) {
      this.last = msg;
    }

    this.listeners.forEach((listener) => listener(msg));
  }
}

const onMessage = new EventSync();
const sendSync = new EventSync(true);

function connect() {
  const ws = new WebSocket(wsURL);

  ws.addEventListener('open', () => {
    console.log('We are connected');

    ws.onmessage = ({ data }) => {
      onMessage.emit(JSON.parse(data));
    };

    sendSync.subscribe((data) => {
      ws.send(JSON.stringify(data));
    });
  });

  ws.onclose = () => {
    console.log('Reconnect to ws');
    setTimeout(connect, 100);
  };
}
!localSync && connect();

export function waitForStatusUpdates(onUpdate) {
  console.log('waitForStatusUpdates');

  onMessage.subscribe(onUpdate);

  sendSync.emit({
    action: 'get_status',
  });
}

export function scheduleStart(delaySec) {
  const startTS = new Date(Date.now() + delaySec * 1000).getTime();
  sendSync.emit({
    action: 'schedule_start',
    mission_start: startTS,
  });

  if (localSync) {
    onMessage.emit({
      status: 'start_scheduled',
      mission_start: startTS,
    });
  }
}

export function StopMission() {
  sendSync.emit({
    action: 'stop',
  });
}
