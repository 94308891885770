import Papa from 'papaparse';

const files = [
  'Spark01_Descent.csv',
  'Spark03_Descent.csv',
  'Spark05_Descent.csv',
  'Spark07_Descent.csv',
  'Spark09_Descent.csv',
];

const SPARK_DATA = {};

function fetchData() {
  const CSV = [];
  return new Promise((resolve) => {
    Promise.all(
      files.map(
        (file) =>
          new Promise((resolve, reject) => {
            fetch(`/Flight_Data/${file}`)
              .then((response) => {
                return response.text();
              })
              .then((csv) => {
                CSV.push(Papa.parse(csv));
                resolve();
              })
              .catch(() => {
                reject();
              });
          })
      )
    ).then(() => {
      resolve(CSV);
    });
  });
}

const parseCSV = (CSV) => {
  const today = new Date();

  CSV.forEach((csv) => {
    const SparkID = csv.data[1][0];
    SPARK_DATA[SparkID] = {
      id: SparkID,
      data: {
        ts: [],
        T_Mission: [],

        Altitude_MSL_ft: [],
        Latitude_deg: [],
        Longitude_deg: [],

        Velocity_mph: [],
        Acceleration_g: [],
        Temperature_F: [],
        Pressure_atm: [],
        RH_percent: [],
      },
    };

    for (let li = 1; li < csv.data.length; li++) {
      const [
        SparkID,
        UTC_H,
        UTC_M,
        UTC_S,
        T_Mission,
        Altitude_MSL_ft,
        Latitude_deg,
        Longitude_deg,
        Velocity_mph,
        Acceleration_g,
        Temperature_F,
        Pressure_atm,
        RH_percent,
      ] = csv.data[li];

      const ts = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDay(),
        UTC_H,
        UTC_M,
        UTC_S,
        0
      );

      if (SPARK_DATA[SparkID] && SPARK_DATA[SparkID].data) {
        SPARK_DATA[SparkID].data['ts'].push(ts.getTime());

        SPARK_DATA[SparkID].data['T_Mission'].push(Number(T_Mission) * 1000);

        SPARK_DATA[SparkID].data['Altitude_MSL_ft'].push(
          Number(Altitude_MSL_ft)
        );
        SPARK_DATA[SparkID].data['Latitude_deg'].push(Number(Latitude_deg));
        SPARK_DATA[SparkID].data['Longitude_deg'].push(Number(Longitude_deg));

        SPARK_DATA[SparkID].data['Velocity_mph'].push(Number(Velocity_mph));
        SPARK_DATA[SparkID].data['Acceleration_g'].push(Number(Acceleration_g));
        SPARK_DATA[SparkID].data['Temperature_F'].push(Number(Temperature_F));
        SPARK_DATA[SparkID].data['Pressure_atm'].push(Number(Pressure_atm));
        SPARK_DATA[SparkID].data['RH_percent'].push(Number(RH_percent));
      }
    }
  });
};

const startPromise = fetchData().then((CSV) => {
  parseCSV(CSV);
});

class YeldIterator {
  constructor(key, start, data) {
    this.key = key;
    this.startTime = start;
    this.data = data;
    this.tOffsets = data['T_Mission'];
    this.lastIndex = -1;

    this.iteration = this.iteration.bind(this);
    this.start = this.start.bind(this);
  }

  iteration() {
    const time = Date.now() - this.startTime;

    for (let i = this.lastIndex + 1; i < this.tOffsets.length; i++) {
      if (this.tOffsets[i] <= time) {
        this.lastIndex = i;

        const msg = {
          SparkID: this.key,
        };

        Object.keys(this.data).forEach((msgKey) => {
          msg[msgKey] = this.data[msgKey][i];
        });

        DataService.dispatchMessage(msg);
      } else {
        break;
      }
    }

    if (this.lastIndex === this.tOffsets.length && this.interval) {
      clearInterval(this.interval);
    }
  }

  start() {
    this.interval = setInterval(this.iteration, 100);
  }
}

class DataProviderService {
  #datastreamScheduled = false;
  #iterators;

  constructor() {
    this.messages = [];
    this.subscribers = [];
    this.#iterators = [];
  }

  async #streamData(start) {
    await startPromise;
    start = start || Date.now();
    const keys = Object.keys(SPARK_DATA);

    keys
      .map((key) => {
        const it = new YeldIterator(key, start, SPARK_DATA[key].data);
        this.#iterators.push(it);
        return it;
      })
      .forEach((iter) => iter.start());
  }

  subscribe(subscriber, fetchHistory) {
    if (fetchHistory) {
      this.messages.forEach((msg) => subscriber(msg));
    }

    this.subscribers.push(subscriber);
  }

  dispatchMessage(msg) {
    this.subscribers.forEach((sub) => sub(msg));
    this.messages.push(msg);
  }

  stopDataStream() {
    this.#iterators.forEach((it) => {
      clearInterval(it.interval);
    });
    this.#iterators = [];
    this.#datastreamScheduled = false;
  }

  scheduleDataStream(startDate) {
    if (this.#datastreamScheduled) {
      return;
    }
    this.#datastreamScheduled = true;

    const now = Date.now();
    const start = startDate.getTime();
    const timeout = start >= now ? start - now : 0;

    if (timeout <= 0) {
      this.#streamData(start);
    } else {
      setTimeout(() => {
        this.#streamData();
      }, timeout);
    }
  }
}

export const DataService = new DataProviderService();
