import { useEffect, useRef, useState } from 'react';
import styles from './LivestreamView.module.css';

import DailyIframe from '@daily-co/daily-js';

export default function LivestreamView({
  url,
  token,
  user,
}) {
  const callFrameRef = useRef();
  const canSend = useRef(false);

  useEffect(() => {
    callFrameRef.current = DailyIframe.createFrame(document.getElementById('daily'), {
      showLeaveButton: false,
      showFullscreenButton: true,
      iframeStyle: {
        top: '10',
        left: '0',
        width: '100%',
        height: '100%',
      },
      activeSpeakerMode: true,
      showParticipantsBar: false,
      showLocalVideo: false,
      dailyConfig: {
        micAudioMode: user === 'broadcaster' ? 'music' : 'speech',
      },
    });

    callFrameRef.current.setBandwidth({
      kbs: 'NO_CAP',
      trackConstraints: {
        width: { min: 1920, max: 1920, ideal: 1920 },
        height: { min: 1080, max: 1080, ideal: 1080 },
        maxFramerate: 30,
      },
    });
  }, []);

  useEffect(() => {
    if (url && token && callFrameRef.current) {
      callFrameRef.current.join({ url, token });
      callFrameRef.current.on('participant-updated', (event) => {
        const receivedCanSend = event.participant.permissions.canSend;

        if (!canSend.current && receivedCanSend) {
          setTimeout(() => {
            callFrameRef.current.setLocalVideo(true);
            callFrameRef.current.setLocalAudio(true);
          }, 1000);
        }

        canSend.current = !!receivedCanSend;
      });
    }
  }, [url, token, callFrameRef.current]);

  return (
    <>
      <div className={styles.container} id="daily" />
    </>
  );
}
