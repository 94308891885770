import { useCallback, useEffect, useState } from 'react';

import {
  Alert,
  Button,
  Grid,
  Paper,
  Snackbar,
  TextField,
  Typography,
  Stack,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  Input,
  FormGroup,
  FormControlLabel,
  Switch,
  Divider,
  Tab,
  Tabs,
  Box,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';

import styles from './Admin.module.css';
import { chipSatConfigs } from './config';
import {
  scheduleStart,
  StopMission,
  waitForStatusUpdates,
} from './services/SyncService';
import AdminLivestreamView from './components/AdminLivestreamView';

function p(n, c = 2) {
  return String(n).padStart(c, '0');
}

function formatDuration(time) {
  time = Math.floor(time / 1000);
  const seconds = time % 60;
  const minutes = Math.floor(time / 60) % 60;
  const hours = Math.floor(minutes / 360);
  return `${p(hours)}:${p(minutes)}:${p(seconds)}`;
}

function formatCountdown(time) {
  time = -Math.floor(time / 1000);
  const seconds = time % 60;
  return `${seconds}`;
}

export default function Admin(props) {
  const navigate = useNavigate();
  const [start, setStart] = useState(null);
  const [now, setNow] = useState(new Date());
  const [saving, setSaving] = useState(false);
  const [message, setMessage] = useState(null);
  const [chipSats, setChipSats] = useState(chipSatConfigs);
  const [loadingConfig, setLoadingConfig] = useState(true);
  const [passwords, setPasswords] = useState({});
  const [settingPasswords, setSettingPasswords] = useState(false);
  const [authToken, setAuthToken] = useState(null);
  const [dailyUrl, setDailyUrl] = useState(null);
  const [dailyToken, setDailyToken] = useState(null);
  const [participants, setParticipants] = useState({});

  const handleUpdateDaily = async () => {
    if (authToken) {
      await fetch(`${process.env.REACT_APP_API_URL}/daily`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`,
        },
      })
        .then((result) => result.json())
        .then(({ token, url }) => {
          setDailyToken(token);
          setDailyUrl(url);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const handleSetPasswords = (username, password) => {
    setPasswords({...passwords, [username]: password});
  };
  
  const handleUpdatePasswords = async () => {
    setSettingPasswords(true);
    const token = localStorage.getItem('token');
    const response = await fetch(`${process.env.REACT_APP_API_URL}/setpasswords`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(passwords),
    });
    if (response.ok) {
      setMessage('Passwords updated');
    } else {
      setMessage('Failed to update passwords');
    }
    setSettingPasswords(false);
    setPasswords({});
  };

  const [username, setUsername] = useState('');
  const handleSetUsername = (e) => setUsername(e.target.value);
  const [password, setPassword] = useState('');
  const handleSetPassword = (e) => setPassword(e.target.value);
  const [user, setUser] = useState(null);
  const [authenticating, setAuthenticating] = useState(true);
  const [authenticationError, setAuthenticationError] = useState(false);

  useEffect(() => {
    if (user && authToken) {
      handleUpdateDaily();
    }
  }, [user, authToken]);

  const handleAuthenticate = useCallback(async () => {
    if (username !== 'admin') {
      setAuthenticationError(true);

      return;
    }

    setAuthenticating(true);
    setAuthenticationError(false);
    
    let token;
    try {
      const result = await fetch(`${process.env.REACT_APP_API_URL}/auth`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });
      ({ token } = JSON.parse(await result.text()));
    } catch (e) {
      setAuthenticationError(true);
    }

    if (token) {
      setUser(username);
      setAuthToken(token);

      localStorage.setItem('token', token);

      setAuthenticationError(false);
    }

    setAuthenticating(false);
  }, [username, password]);

  const handleLogout = useCallback(() => {
    setUser(null);
    setAuthToken(null);
    localStorage.removeItem('token');
  }, []);

  useEffect(() => {
    if (!user && authenticating && !authenticationError) {
      const token = localStorage.getItem('token');
      if (token) {
        fetch(`${process.env.REACT_APP_API_URL}/auth`, {
          method: 'POST',
          headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
          body: JSON.stringify({ token }),
        })
          .then((result) => result.json())
          .then(({ username }) => {
            if (username !== 'admin') {
              navigate('/');
            }

            setAuthToken(token);
            setUser(username);
          })
          .catch(() => {
            setAuthenticating(false);
          })
          .finally(() => {
            setAuthenticating(false);
          });
      } else {
        setAuthenticating(false);
      }
    }
  }, [user, authenticating, authenticationError]);

  useEffect(() => {
    const id = setInterval(() => setNow(new Date()), 1000);
    return () => clearInterval(id);
  }, []);

  const startButtonClickHandler = useCallback(() => {
    scheduleStart(5);
  }, []);

  const stopButtonClickHandler = useCallback(() => {
    StopMission();
  }, []);

  useEffect(() => {
    waitForStatusUpdates((msg) => {
      if (msg.status === 'start_scheduled') {
        setStart(msg['mission_start']);
      }
      if (msg.command === 'stop') {
        setStart(null);
      }
    });
  }, []);

  useEffect(() => {
    if (authToken) {
      fetch(`${process.env.REACT_APP_API_URL}/chipSatConfig`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`,
        },
      })
        .then((result) => result.json())
        .then((result) => {
          const newConfig = result.reduce((acc, item) => {
            const [itemId, itemConfig] = item;
            return {
              ...acc,
              [itemId]: {
                ...acc[itemId],
                ...itemConfig,
              },
            };
          }, chipSats);
  
          setChipSats(newConfig);
          setLoadingConfig(false);
        });
    }
  }, [authToken]);

  useEffect(() => {
    if (authToken && user === 'admin') {
      fetch(`${process.env.REACT_APP_API_URL}/daily`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`,
        },
        body: JSON.stringify({}),
      });
    }
  }, [user, authToken]);

  const [adminOnly, setAdminOnly] = useState(false);
  const [streamRestricted, setstreamRestricted] = useState([]);

  const handleToggleAdminOnly = () => {
    setAdminOnly(!adminOnly);
  };
  const handleTogglestreamRestricted = (id) => {
    if (streamRestricted.includes(id)) {
      setstreamRestricted(streamRestricted.filter((i) => i !== id));
    } else {
      setstreamRestricted([...streamRestricted, id]);
    }
  };

  const timeElapsed = now - start;

  const [tabIndex, setTabIndex] = useState(0);
  const handleChangeTabIndex = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <div className={styles.container}>
      <Snackbar
        open={Boolean(message)}
        autoHideDuration={5000}
        onClose={() => setMessage(null)}
      >
        <Alert onClose={() => setMessage(null)} severity={message?.severity}>
          {message?.message}
        </Alert>
      </Snackbar>
      <Paper className={styles.body}>
        <Grid container spacing={2}>
          <Grid item spacing={2} xs={12}>
            <Typography variant="h4">Mission: Subspace Admin</Typography>
          </Grid>
          {user === 'admin' ? (
            <>
              <Grid container item spacing={2} xs={4}>
              <Grid item xs={12}>
                <Typography variant="h6">Chipsat Names</Typography>
              </Grid>
              {loadingConfig ? (
                <>Loading...</>
              ) : (
                <>
                  {Object.entries(chipSats).map(([key, chip]) => (
                    <Grid item xs={12}>
                      <TextField
                        label={`Chipsat ${key}`}
                        value={chip.name}
                        onChange={(e) =>
                          setChipSats({
                            ...chipSats,
                            [key]: { ...chip, name: e.target.value },
                          })
                        }
                      />
                    </Grid>
                  ))}
                </>
              )}
              <Grid item xs={12}>
                <LoadingButton
                  loading={saving}
                  onClick={() => {
                    setSaving(true);
                    setTimeout(
                      () => {
                        const token = localStorage.getItem('token');

                        fetch(`${process.env.REACT_APP_API_URL}/chipSatConfig`, {
                          method: 'POST',
                          headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                          },
                          body: JSON.stringify(
                            Object.entries(chipSats).map(([k, v]) => [
                              k,
                              { name: v.name },
                            ])
                          ),
                        })
                          .catch(() => {
                            setMessage({
                              severity: 'error',
                              message: 'Error saving chipsat names!',
                            });
                          })
                          .then((response) => response.json())
                          .then(() => {
                            setMessage({
                              severity: 'success',
                              message: 'Successfully saved chipsat names!',
                            });
                          })
                          .finally(() => setSaving(false))
                      },
                      1500
                    );
                  }}
                  variant="outlined"
                >
                  Save
                </LoadingButton>
              </Grid>
              </Grid>
              <Grid container item spacing={2} xs={4}>
                <Grid item xs={12}>
                  <Stack spacing={2}>
                    <Typography variant="h6">Set passwords</Typography>
                    <TextField
                      type="password"
                      label="Admin password"
                      value={passwords.admin || ''}
                      onChange={(e) => handleSetPasswords('admin', e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <LoadingButton
                            loading={passwords.admin && settingPasswords}
                            onClick={handleUpdatePasswords}
                            variant="outlined"
                          >
                            Set
                          </LoadingButton>
                        )}
                      }
                    />
                    <TextField
                      type="password"
                      label="Broadcaster password"
                      value={passwords.broadcaster || ''}
                      onChange={(e) => handleSetPasswords('broadcaster', e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <LoadingButton
                            loading={passwords.broadcaster && settingPasswords}
                            onClick={handleUpdatePasswords}
                            variant="outlined"
                          >
                            Set
                          </LoadingButton>
                        )}
                      }
                    />
                    <TextField
                      type="password"
                      label="Teacher password"
                      value={passwords.teacher || ''}
                      onChange={(e) => handleSetPasswords('teacher', e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <LoadingButton
                            loading={passwords.teacher && settingPasswords}
                            onClick={handleUpdatePasswords}
                            variant="outlined"
                          >
                            Set
                          </LoadingButton>
                        )}
                      }
                    />
                    <TextField
                      type="password"
                      label="Student password"
                      value={passwords.student || ''}
                      onChange={(e) => handleSetPasswords('student', e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <LoadingButton
                            loading={passwords.student && settingPasswords}
                            onClick={handleUpdatePasswords}
                            variant="outlined"
                          >
                            Set
                          </LoadingButton>
                        )}
                      }
                    />
                  </Stack>
                </Grid>
              </Grid>
              <Grid container item spacing={2} xs={4}>
                <Grid item xs={12}>
                  <Typography variant="h6">Stream settings</Typography>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabIndex} onChange={handleChangeTabIndex} aria-label="tabs" variant="fullWidth">
                      <Tab label="Stream" id="tab-0" aria-controls="tabpanel-0" />
                      <Tab label="Participants" id="tab-1" aria-controls="tabpanel-1" />
                    </Tabs>
                  </Box>
                  <div
                    className={styles.livestreamPane}
                    role="tabpanel"
                    hidden={tabIndex !== 0}
                    id="tabpanel-0"
                    aria-labelledby="tab-0"
                  >
                    {dailyUrl && dailyToken && (
                      <AdminLivestreamView
                        url={dailyUrl}
                        token={dailyToken}
                        adminOnly={adminOnly}
                        streamRestricted={streamRestricted}
                        setParticipants={setParticipants}
                      />
                    )}
                  </div>
                  <div
                    role="tabpanel"
                    hidden={tabIndex !== 1}
                    id="tabpanel-1"
                    aria-labelledby="tab-1"
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={<Switch />}
                        label="Restrict stream to admin"
                        checked={adminOnly}
                        onChange={handleToggleAdminOnly}
                        inputProps={{ 'aria-label': 'controlled' }} 
                      />
                      <Divider />
                      {Object.entries(participants).map(
                        ([key, participant]) => !participant.local && (
                          <FormControlLabel
                            key={key}
                            control={<Switch />}
                            label={participant.user_name}
                            checked={!streamRestricted.includes(participant.user_id) && (!adminOnly || participant.owner)}
                            onChange={() => handleTogglestreamRestricted(participant.user_id)}
                            inputProps={{ 'aria-label': 'controlled' }} 
                          />
                        )
                      )}
                    </FormGroup>
                  </div>
                </Grid>   
              </Grid>
              <Grid container item spacing={2} xs={12} className={styles.center}>
                <Grid item xs={12}>
                  <Typography variant="h6">Mission Control</Typography>
                </Grid>
                <Grid item xs={12}>
                  {start ? (
                    <Button variant="outlined" onClick={stopButtonClickHandler}>
                      Stop
                    </Button>
                  ) : (
                    <Button variant="outlined" onClick={startButtonClickHandler}>
                      Start
                    </Button>
                  )}
                </Grid>
                <Grid item xs={12}>
                  {start ? (
                    <Typography>
                      {timeElapsed >= 0
                        ? `Time Elapsed: ${formatDuration(now - start)}`
                        : `${formatCountdown(timeElapsed)}`}
                    </Typography>
                  ) : null}
                </Grid>
              </Grid>
              <Grid container item spacing={2} xs={12} className={styles.center}>
                <Grid item xs={12}>
                  <Stack spacing={2}>
                    <Button onClick={handleLogout}>Logout</Button>
                  </Stack>
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <div className={styles.loginForm}>
                <Stack spacing={2}>
                  {authenticating ? (
                    <CircularProgress />
                  ) : (
                    <>
                      <FormControl>
                        <InputLabel htmlFor="username-input">User</InputLabel>
                        <Input id="username-input" aria-describedby="username-helper-text" error={authenticationError} onChange={handleSetUsername} />
                      </FormControl>
                      <FormControl>
                        <InputLabel htmlFor="password-input">Password</InputLabel>
                        <Input id="password-input" aria-describedby="my-helper-text" error={authenticationError} onChange={handleSetPassword} type="password" />
                        {authenticationError && (
                          <FormHelperText id="my-helper-text" error>
                            Invalid username or password
                          </FormHelperText>
                        )}
                      </FormControl>
                      <Button variant="outlined" onClick={handleAuthenticate}>Login</Button>
                    </>
                  )}
                </Stack>
              </div>
            </Grid> 
          )}
        </Grid>
      </Paper>
    </div>
  );
}
