import CesiumViewerProvider from './CesiumViewerProvider';
import SparkEntity from './SparkEntity';
import * as Cesium from 'cesium';
import React, { useState } from 'react';
import CesiumCamera from './CesiumCamera';

const ft2m = 0.3048;

export function CesiumViewer({ data, config }) {
  const [camType, setCamType] = useState('group');

  const positions = Object.keys(data.sparks).map((sparkId) => {
    const spark = data.sparks[sparkId];
    const {
      Longitude_deg: lon,
      Latitude_deg: lat,
      Altitude_MSL_ft: alt,
    } = spark.data;
    return Cesium.Cartesian3.fromDegrees(lon, lat, alt * ft2m);
  });

  if (camType === 'full') {
    Object.keys(data.sparks).forEach((sparkId) => {
      const spark = data.sparks[sparkId];
      const [lon, lat, alt] = spark.coordinates[0];
      positions.push(Cesium.Cartesian3.fromDegrees(lon, lat, alt * ft2m));
    });
  }
  const boundingSphere = Cesium.BoundingSphere.fromPoints(positions);

  const sparks = Object.keys(data.sparks).map((sparkId) => {
    const spark = data.sparks[sparkId];
    const title = config ? config[sparkId].name : sparkId;
    const color = config ? config[sparkId].borderColor : 'rgba(8, 178, 227, 0.5)';

    return (
      <SparkEntity
        key={sparkId}
        sparkId={sparkId}
        sparkData={spark}
        title={title}
        color={color}
      />
    );
  });

  const toolbar = (
    <>
      <span style={{ color: 'white' }}>Camera:&nbsp;</span>
      <select
        value={camType}
        onChange={({ target }) => {
          setCamType(target.value);
        }}
        style={{ width: '100px', marginRight: '10px' }}
      >
        <option value={'group'}>Follow group</option>
        <option value={'full'}>Show trajectory</option>
        <option value={'topdown'}>Top view</option>
      </select>
    </>
  );

  return (
    <CesiumViewerProvider toolbarElements={toolbar}>
      <CesiumCamera track={boundingSphere} type={camType} />
      {sparks}
    </CesiumViewerProvider>
  );
}
