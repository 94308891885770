import { useRef, useState } from 'react';
import styles from './FullscreenWrapper.module.css';
import { Fullscreen, FullscreenExit } from '@mui/icons-material';
import { IconButton } from '@mui/material';

export default function FullscreenWrapper({
  children,
  beforeToggle = () => {},
}) {
  const [fullscreen, setFullscreen] = useState(false);
  const ref = useRef();
  return (
    <div
      ref={ref}
      className={`${styles.wrapper} ${fullscreen ? styles.fullscreen : ''}`}
    >
      <IconButton
        className={styles.button}
        onClick={() => {
          beforeToggle(fullscreen);
          const e = ref.current;
          if (fullscreen) {
            if (document.exitFullscreen) document.exitFullscreen();
            else if (document.webkitExitFullscreen)
              document.webkitExitFullscreen();
            else if (document.msExitFullscreen) document.msExitFullscreen();
          } else {
            if (e.requestFullscreen) e.requestFullscreen();
            else if (e.webkitRequestFullscreen) e.webkitRequestFullscreen();
            else if (e.msRequestFullscreen) e.msRequestFullscreen();
          }
          setFullscreen(!fullscreen);
        }}
        size="small"
      >
        {fullscreen ? <FullscreenExit /> : <Fullscreen />}
      </IconButton>
      {children}
    </div>
  );
}
