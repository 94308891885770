import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
// eslint-disable-next-line no-unused-vars
import ChartJS from 'chart.js/auto';
import { useEffect, useRef, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { CHART_VARIABLES } from '../config';

import styles from './ChartViewer.module.css';
import FullscreenWrapper from './FullscreenWrapper';

const CHART_VARS_BY_NAME = CHART_VARIABLES.reduce(
  (acc, cur) => ({
    ...acc,
    [cur.name]: {
      ...cur,
    },
  }),
  {}
);

function getValueForVariable(data, variable, options) {
  return CHART_VARS_BY_NAME[variable].getValue(data, options);
}

const INITIAL_CHART_OPTIONS = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    decimation: {
      enabled: true,
    },
  },
};

export default function ChartViewer({
  sparks,
  config,
  defaultXVariable = CHART_VARIABLES[0].name,
  defaultYVariable = CHART_VARIABLES[1].name,
}) {
  const [data, setData] = useState(null);
  const [options, setOptions] = useState(null);
  const [xSelection, setXSelection] = useState(defaultXVariable);
  const [ySelection, setYSelection] = useState(defaultYVariable);
  const [originalSize, setOriginalSize] = useState(defaultYVariable);
  const chartRef = useRef(null);

  useEffect(() => {
    if (!xSelection) {
      return;
    }

    setOptions({
      ...INITIAL_CHART_OPTIONS,
      scales: {
        x: {
          type: 'linear',
          position: 'bottom',
          ticks: {
            callback: CHART_VARS_BY_NAME[xSelection].transform,
          },
        },
        y: {
          ticks: {
            callback: CHART_VARS_BY_NAME[ySelection].transform,
          },
        },
      },
    });
  }, [xSelection, ySelection]);

  useEffect(() => {
    if (!sparks) {
      return;
    }
    setData({
      // labels: times,
      datasets: Object.keys(sparks).map((sparkID) => {
        return {
          label: config ? config[sparkID].name : sparkID,
          data: sparks[sparkID].history.map((dataPoint) => ({
            x: getValueForVariable(dataPoint, xSelection),
            y: getValueForVariable(dataPoint, ySelection),
          })),
          borderColor: config ? config[sparkID].borderColor : 'red',
          backgroundColor: config ? config[sparkID].backgroundColor: 'white',
        };
      }),
    });
  }, [xSelection, ySelection, sparks]);

  const ref = useRef();

  return (
    <FullscreenWrapper
      beforeToggle={(fullscreen) => {
        if (!fullscreen) {
          setOriginalSize([chartRef.current.width, chartRef.current.height]);
        } else {
          chartRef.current.resize(...originalSize);
        }
      }}
    >
      <div className={styles.container}>
        <div className={styles.header} ref={ref}>
          <FormControl className={styles.formControl} variant="filled">
            <Select
              value={ySelection}
              onChange={(e) => setYSelection(e.target.value)}
              inputProps={{ className: styles.select }}
              MenuProps={{
                container: ref.current,
              }}
            >
              {CHART_VARIABLES.filter((variable) => !variable.disableYAxis).map(
                (variable) => (
                  <MenuItem key={variable.name} value={variable.name}>
                    {variable.name}
                  </MenuItem>
                )
              )}
            </Select>
            <FormHelperText>Y axis</FormHelperText>
          </FormControl>
          <Typography> vs. </Typography>
          <FormControl className={styles.formControl} variant="filled">
            <Select
              value={xSelection}
              onChange={(e) => setXSelection(e.target.value)}
              inputProps={{ className: styles.select }}
              MenuProps={{
                container: ref.current,
              }}
            >
              {CHART_VARIABLES.map((variable) => (
                <MenuItem key={variable.name} value={variable.name}>
                  {variable.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>X axis</FormHelperText>
          </FormControl>
        </div>
        <div className={styles.chartContainer}>
          {data && options && (
            <Chart
              className={styles.chart}
              type={'line'}
              options={options}
              data={data}
              ref={chartRef}
            />
          )}
        </div>
      </div>
    </FullscreenWrapper>
  );
}
