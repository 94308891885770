import { Routes, Route, Navigate } from 'react-router-dom';

import { StyledEngineProvider } from '@mui/material/styles';
import { ThemeProvider } from '@mui/system';

import styles from './App.module.css';
import Portal from './components/Portal';
import Admin from './Admin';
import theme from './theme';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <div className={styles.App}>
          <Routes>
            <Route path="/admin" element={<Admin />} />
            <Route path="/" element={<Portal />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </div>
      </StyledEngineProvider>
    </ThemeProvider>
  );
}

export default App;
