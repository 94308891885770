import { useEffect, useRef, useState } from 'react';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Cesium from 'cesium';

import styles from './CesiumViewer.module.css';

window.CESIUM_BASE_URL = '/lib/cesium/';
Cesium.Ion.defaultAccessToken =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.' +
  'eyJqdGkiOiI5MTAxNmIxNy0yODQ1LTQxZGUtOTZlNC03NTIxNTFmMWY4ODAiLCJpZCI6NzExNDksImlhdCI6MTYzNDkwNzAyM30.' +
  'f11gxMyzLZKoFIaKosEPmIZ4xxZTsm7huEhdyI5HzQU';

export const CesiumViewerContext = React.createContext();

export default function CesiumViewerProvider({ children, toolbarElements }) {
  const containerRef = useRef();

  const [viewer, setViewer] = useState();

  useEffect(() => {
    if (containerRef.current) {
      const viewer = new Cesium.Viewer(containerRef.current, {
        geocoder: false,
        infoBox: false,
        scene3DOnly: true,
        navigationInstructionsInitiallyVisible: false,
        navigationHelpButton: false,
        sceneModePicker: false,
        timeline: false,
        animation: false,
        homeButton: false,
        terrainProvider: Cesium.createWorldTerrain(),
        fullscreenElement: containerRef.current,
      });
      setViewer(viewer);
    }
  }, []);

  useEffect(() => {
    if (viewer) {
      if (React.isValidElement(toolbarElements)) {
        const toolbar = document.querySelector('.cesium-viewer-toolbar');

        if (document.querySelector('.cesium-viewer-toolbar .react-wrapper')) {
          const reactWrapper = document.querySelector(
            '.cesium-viewer-toolbar .react-wrapper'
          );

          ReactDOM.render(toolbarElements, reactWrapper);
        } else {
          const reactWrapper = document.createElement('span');
          reactWrapper.className = 'react-wrapper';
          toolbar.insertBefore(reactWrapper, toolbar.firstChild);
          ReactDOM.render(toolbarElements, reactWrapper);
        }
      }
    }
  }, [viewer, toolbarElements]);

  return (
    <div id="cesiumContainer" ref={containerRef} className={styles.container}>
      <CesiumViewerContext.Provider value={viewer}>
        {children}
      </CesiumViewerContext.Provider>
    </div>
  );
}
