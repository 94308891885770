import {
  FormControl,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { useState, useRef, useEffect, useCallback } from 'react';
import { defaults, LEADERBOARD_METRICS } from '../config';
import FullscreenWrapper from './FullscreenWrapper';

import styles from './Leaderboard.module.css';

const LEADERBOARD_METRICS_BY_NAME = LEADERBOARD_METRICS.reduce(
  (acc, cur) => ({
    ...acc,
    [cur.name]: cur,
  }),
  {}
);

function getNthFormatted(index) {
  return ['1st', '2nd', '3rd', '4th', '5th'][index];
}

export default function Leaderboard({ sparks, config }) {
  const [selectedMetric, setSelectedMetric] = useState(
    defaults.leaderboardMetric.name
  );

  const ref = useRef();

  // For some reason this is needed to make the select work properly
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  useEffect(() => {
    forceUpdate();
  }, [forceUpdate]);

  return (
    <FullscreenWrapper>
      <Paper className={styles.container}>
        <div className={styles.header} ref={ref}>
          <Typography variant="h6">Leaderboard</Typography>
          <FormControl variant="filled">
            <Select
              value={selectedMetric}
              MenuProps={{
                container: ref.current,
              }}
              onChange={(e) => setSelectedMetric(e.target.value)}
              inputProps={{ className: styles.select }}
            >
              {LEADERBOARD_METRICS.map((metric) => (
                <MenuItem key={metric.name} value={metric.name}>
                  {metric.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <TableContainer>
          <Table>
            <TableBody>
              {sparks &&
                Object.keys(sparks)
                  .map((sparkID) => sparks[sparkID].data)
                  .sort(LEADERBOARD_METRICS_BY_NAME[selectedMetric].sort)
                  .map((spark, index) => (
                    <TableRow key={spark.SparkID}>
                      <TableCell className={styles.nthCol}>
                        {getNthFormatted(index)}
                      </TableCell>
                      <TableCell>{config ? config[spark.SparkID].name : spark.SparkID}</TableCell>
                      <TableCell>
                        {LEADERBOARD_METRICS_BY_NAME[selectedMetric].getValue(
                          spark
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </FullscreenWrapper>
  );
}
