import { useEffect, useContext, useRef } from 'react';
import React from 'react';
import * as Cesium from 'cesium';

import { CesiumViewerContext } from './CesiumViewerProvider';

const ft2m = 0.3048;

export default function SparkEntity({ sparkId, sparkData, color, title }) {
  const viewer = useContext(CesiumViewerContext);

  const {
    Longitude_deg: lon,
    Latitude_deg: lat,
    Altitude_MSL_ft: alt,
  } = sparkData.data;

  const coordinatesRef = React.useRef([]);
  const coordinates = coordinatesRef.current;

  if (sparkData && sparkData.coordinates) {
    for (let i = coordinates.length; i < sparkData.coordinates.length; i++) {
      const [lon, lat, alt] = sparkData.coordinates[i];
      coordinates.push(Cesium.Cartesian3.fromDegrees(lon, lat, alt * ft2m));
    }
  }

  const pointsCallbackRef = useRef();
  // Use ref here, to register immutable callback
  // inside CallbackProperty, but update actual
  // function on each data change
  pointsCallbackRef.current = () => {
    return coordinates;
  };

  const positionCBRef = useRef();
  positionCBRef.current = () => {
    return Cesium.Cartesian3.fromDegrees(lon, lat, alt * ft2m);
  };

  const heightCBRef = useRef();
  heightCBRef.current = () => {
    return alt * ft2m;
  };

  const entityRef = useRef(
    new Cesium.Entity({
      position: new Cesium.CallbackProperty(() => {
        return positionCBRef.current();
      }, false),
      height: new Cesium.CallbackProperty(() => {
        return heightCBRef.current();
      }, false),
      polyline: new Cesium.PolylineGraphics({
        positions: new Cesium.CallbackProperty(() => {
          return pointsCallbackRef.current();
        }, false),
        material: Cesium.Color.fromCssColorString(color),
        width: 1.8,
      }),
      point: {
        pixelSize: 3,
        color: Cesium.Color.fromCssColorString(color),
        outlineColor: Cesium.Color.WHITE,
        outlineWidth: 1,
      },
      label: {
        text: title,
        font: '12pt monospace',
        style: Cesium.LabelStyle.FILL,
        fillColor: Cesium.Color.WHITE,
        backgroundColor: Cesium.Color.BLACK.withAlpha(0.5),
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
        pixelOffset: new Cesium.Cartesian2(9, -9),
        showBackground: true,
      },
    })
  );

  useEffect(() => {
    const entity = entityRef.current;

    if (viewer && entity) {
      viewer.entities.add(entity);
    }

    return () => {
      if (viewer && entity) {
        viewer.entities.remove(entity);
      }
    };
  }, [viewer, entityRef]);

  useEffect(() => {
    entityRef.current.label = {
      ...entityRef.current.label,
      text: title,
      font: '12pt monospace',
      style: Cesium.LabelStyle.FILL,
      fillColor: Cesium.Color.WHITE,
      backgroundColor: Cesium.Color.BLACK.withAlpha(0.5),
      verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
      horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
      pixelOffset: new Cesium.Cartesian2(9, -9),
      showBackground: true,
    };
  }, [title]);

  return <></>;
}
