import { useEffect, useRef } from 'react';
import styles from './AdminLivestreamView.module.css';

import DailyIframe from '@daily-co/daily-js';

export default function AdminLivestreamView({
  url,
  token,
  adminOnly,
  streamRestricted,
  setParticipants,
}) {
  const callFrameRef = useRef();

  useEffect(() => {
    callFrameRef.current = DailyIframe.createFrame(document.getElementById('daily'), {
      showLeaveButton: false,
      showFullscreenButton: true,
      iframeStyle: {
        top: '10',
        left: '0',
        width: '100%',
        height: '100%',
      },
    });
  }, []);

  useEffect(() => {
    if (url && token && callFrameRef.current) {
      callFrameRef.current.join({ url, token });
      callFrameRef.current.on('participant-updated', (event) => {
        const currentLocalParticipant = callFrameRef.current.participants().local;

        if (event.participant.local && event.participant.permissions.canSend && !currentLocalParticipant.permissions.canSend) {
          callFrameRef.current.setLocalVideo(true);
          callFrameRef.current.setLocalAudio(true);
        }
      });
    }
  }, [url, token, callFrameRef.current]);

  useEffect(() => {
    if (callFrameRef.current) {
      callFrameRef.current.on('participant-joined', (event) => {
        setParticipants(callFrameRef.current.participants());
      });
      callFrameRef.current.on('participant-left', (event) => {
        setParticipants(callFrameRef.current.participants());
      });
    }
  }, [setParticipants]);

  useEffect(() => {
    if (callFrameRef.current) {
      const participants = callFrameRef.current.participants();

      Object.entries(participants).forEach(([id, participant]) => {
        if (id === 'local') {
          return;
        }

        const isAdmin = participant.owner;

        if (!streamRestricted.includes(id) && (!adminOnly || isAdmin)) {
          callFrameRef.current.updateParticipant(id, {
            setAudio: true,
            setVideo: true,
            updatePermissions: {
              canSend: new Set(['audio', 'video']),
            },
          });
        } else {
          callFrameRef.current.updateParticipant(id, {
            setAudio: false,
            setVideo: false,
            updatePermissions: {
              canSend: false,
            },
          });
        }
      });
    }
  }, [adminOnly, streamRestricted.length]);

  return (
    <>
      <div className={styles.container} id="daily" />
    </>
  );
}
