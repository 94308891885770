import isNull from 'lodash/isNull';
import LivestreamView from './LivestreamView';

import styles from './Portal.module.css';
import newAscentLogo from '../images/new-ascent-logo.png';
import missionSubspaceLogo from '../images/mission-subspace-logo.png';

import { CesiumViewer } from './Cesium/CesiumViewer';
import { DataService } from '../services/DataProviderService';
import { useCallback, useEffect, useRef, useState } from 'react';
import ChartViewer from './ChartViewer';

import { waitForStatusUpdates, scheduleStart } from '../services/SyncService';

import StatsViewer from './StatsViewer';
import Leaderboard from './Leaderboard';
import { getDistanceInMeters } from '../geoHelpers';
import { CHART_VARIABLES, localSync, chipSatConfigs } from '../config';
import { Button, Paper, Typography, Stack, FormControl, InputLabel, Input, FormHelperText, CircularProgress } from '@mui/material';
import moment from 'moment';

export default function Portal() {
  const [loadingConfig, setLoadingConfig] = useState(false);
  const [username, setUsername] = useState('');
  const handleSetUsername = (e) => setUsername(e.target.value);
  const [password, setPassword] = useState('');
  const handleSetPassword = (e) => setPassword(e.target.value);
  const [user, setUser] = useState(null);
  const [authToken, setAuthToken] = useState(null);
  const [authenticating, setAuthenticating] = useState(true);
  const [authenticationError, setAuthenticationError] = useState(false);
  const [dailyUrl, setDailyUrl] = useState(null);
  const [dailyToken, setDailyToken] = useState(null);

  const handleAuthenticate = useCallback(async () => {
    setAuthenticating(true);
    setAuthenticationError(false);
    
    let token;
    try {
      const result = await fetch(`${process.env.REACT_APP_API_URL}/auth`, {
        method: 'POST',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password }),
      });
      ({ token } = JSON.parse(await result.text()));
    } catch (e) {
      setAuthenticationError(true);
    }

    if (token) {
      setUser(username);
      setAuthToken(token);

      localStorage.setItem('token', token);

      setAuthenticationError(false);
    }

    setAuthenticating(false);
    setLoadingConfig(true);
  }, [username, password]);

  const handleLogout = useCallback(() => {
    setUser(null);
    setAuthToken(null);
    localStorage.removeItem('token');
  }, []);

  useEffect(() => {
    if (!user && authenticating && !authenticationError) {
      const token = localStorage.getItem('token');

      if (token) {
        fetch(`${process.env.REACT_APP_API_URL}/auth`, {
          method: 'POST',
          headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
          body: JSON.stringify({ token }),
        })
          .then((result) => result.json())
          .then(({ username }) => {
            setUser(username);
            setAuthToken(token);
          })
          .catch(() => {
            setAuthenticating(false);
          })
          .finally(() => {
            setAuthenticating(false);
            setLoadingConfig(true);
          });
      } else {
        setAuthenticating(false);
        setLoadingConfig(true);
      }
    }
  }, [user, authenticating, authenticationError]);

  const [data, updateData] = useState({
    sparks: {},
  });
  const [elapsedTime, setElapsedTime] = useState(null);
  const [config, updateConfig] = useState(chipSatConfigs);
  
  useEffect(() => {
    if (user && loadingConfig) {
      fetch(`${process.env.REACT_APP_API_URL}/chipSatConfig`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`,
        },
      })
        .then((result) => result.json())
        .then((result) => {
          const newConfig = result.reduce((acc, item) => {
            const [itemId, itemConfig] = item;
            return {
              ...acc,
              [itemId]: {
                ...acc[itemId],
                ...itemConfig,
              },
            };
          }, config || {});
  
          updateConfig(newConfig);
          setLoadingConfig(false);
        });
    }
  }, [user, loadingConfig, updateConfig]);

  const [startIsScheduled, setStartIsScheduled] = useState(false);

  const mountedRef = useRef(false);

  const msgHandlerRef = useRef();
  msgHandlerRef.current = (msg) => {
    if (mountedRef.current) {
      const id = msg['SparkID'];

      const spark = data.sparks[id] || {
        data: {},
        coordinates: [],
        history: [],
      };

      let lateral_distance = spark.coordinates.length
        ? 3.28084 *
          getDistanceInMeters(
            spark.coordinates[0][1],
            spark.coordinates[0][0],
            msg.Latitude_deg,
            msg.Longitude_deg
          )
        : 0;

      spark.data = { ...msg, lateral_distance };

      spark.coordinates.push([
        msg['Longitude_deg'],
        msg['Latitude_deg'],
        msg['Altitude_MSL_ft'],
      ]);

      data.sparks = {
        ...data.sparks,
        [id]: spark,
      };

      spark.history.push(spark.data);

      setElapsedTime(
        spark.history.length
          ? moment.duration(msg['ts'] - spark.history[0]['ts'])
          : 0
      );

      updateData({
        ...data,
      });
    }
  };

  useEffect(() => {
    mountedRef.current = true;
    // On Component unmount
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const handleUpdateDaily = async () => {
    if (authToken && (user === 'teacher' || user === 'broadcaster' || user === 'admin')) {
      await fetch(`${process.env.REACT_APP_API_URL}/daily`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`,
        },
      })
        .then((result) => result.json())
        .then(({ token, url }) => {
          setDailyToken(token);
          setDailyUrl(url);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  useEffect(() => {
    handleUpdateDaily();
  }, [user, authToken]);

  useEffect(() => {
    DataService.subscribe((...args) => {
      msgHandlerRef && msgHandlerRef.current(...args);
    });

    waitForStatusUpdates((msg) => {
      if (msg.status === 'start_scheduled') {
        setStartIsScheduled(true);
        DataService.scheduleDataStream(new Date(msg.mission_start));
      }
      if (msg.command === 'stop') {
        setStartIsScheduled(false);
        updateData({ sparks: {} });
        setElapsedTime(null);
        DataService.stopDataStream();
      }
      if (msg.command === 'reload_chip_sat_config') {
        const newConfig = msg.config.reduce((acc, item) => {
          const [itemId, itemConfig] = item;
          return {
            ...acc,
            [itemId]: {
              ...acc[itemId],
              ...itemConfig,
            },
          };
        }, config);

        updateConfig(newConfig);
      }
      if (msg.command === 'daily_update') {
        handleUpdateDaily();
      }
    });
  }, []);

  const startButtonClickHandler = useCallback(() => {
    scheduleStart(5);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.newAscentLogo}>
        <img src={newAscentLogo} alt="" />
      </div>
      <div className={styles.missionSubspaceLogo}>
        <img src={missionSubspaceLogo} alt="" />
      </div>
      <div className={styles.missionStatusPane}>
        <Paper className={styles.missionStatus}>
          {localSync && !elapsedTime && (
            <Button
              variant="outlined"
              disabled={startIsScheduled}
              onClick={startButtonClickHandler}
            >
              Start
            </Button>
          )}
          {elapsedTime ? (
            <Typography>
              Time elapsed:{' '}
              {`${String(elapsedTime.minutes()).padStart(2, '0')}:${String(
                elapsedTime.seconds()
              ).padStart(2, '0')}`}
            </Typography>
          ) : localSync ? (
            ''
          ) : (
            'Waiting for mission start'
          )}
        </Paper>
        {!isNull(user) && (
          <Button onClick={handleLogout}>Logout</Button>
        )}
      </div>
      {!user && (
        <div className={styles.loginPane}>
          <div className={styles.loginForm}>
            <Stack spacing={2}>
              {authenticating ? (
                <CircularProgress />
              ) : (
                <>
                  <FormControl>
                    <InputLabel htmlFor="username-input">User</InputLabel>
                    <Input id="username-input" aria-describedby="username-helper-text" error={authenticationError} onChange={handleSetUsername} />
                  </FormControl>
                  <FormControl>
                    <InputLabel htmlFor="password-input">Password</InputLabel>
                    <Input id="password-input" aria-describedby="my-helper-text" error={authenticationError} onChange={handleSetPassword} type="password" />
                    {authenticationError && (
                      <FormHelperText id="my-helper-text" error>
                        Invalid username or password
                      </FormHelperText>
                    )}
                  </FormControl>
                  <Button variant="outlined" onClick={handleAuthenticate}>Login</Button>
                </>
              )}
            </Stack>
          </div>
        </div>
      )}
      {!!user && (
        <>
          {user === 'student' ? (
            <>
              <div className={styles.cesiumPaneStudent}>
                <CesiumViewer data={data} config={config} />
              </div>
              <div className={styles.statsPaneStudent}>
                <StatsViewer sparks={data.sparks} config={config} />
              </div>
              <div className={styles.leaderboardPaneStudent}>
                <Leaderboard sparks={data.sparks} config={config} />
              </div>
            </>
          ) : (
            <>
              <div className={styles.livestreamPane}>
                <LivestreamView url={dailyUrl} token={dailyToken} user={user} />
              </div>
              <div className={styles.cesiumPane}>
                <CesiumViewer data={data} config={config} />
              </div>
              <div className={styles.statsPane}>
                <StatsViewer sparks={data.sparks} config={config} />
              </div>
              <div className={styles.leaderboardPane}>
                <Leaderboard sparks={data.sparks} config={config} />
              </div>
              </>
          )}
          <div className={styles.chartPaneTopRight}>
            <ChartViewer sparks={data.sparks} config={config} />
          </div>
          <div className={styles.chartPaneBottomRight}>
            <ChartViewer
              sparks={data.sparks}
              config={config}
              defaultXVariable={CHART_VARIABLES[0].name}
              defaultYVariable={CHART_VARIABLES[4].name}
            />
          </div>
        </>
      )}
    </div>
  );
}
