import {
  FormControl,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useRef, useState } from 'react';
import styles from './StatsViewer.module.css';
import FullscreenWrapper from './FullscreenWrapper';

import { STATS_CONFIG } from '../config';

export default function StatsViewer({ sparks, config }) {
  const [selectedID, setSelectedID] = useState('01');

  const hideStats = sparks && Object.keys(sparks).length;

  const ref = useRef();
  return (
    <FullscreenWrapper>
      <Paper className={styles.container}>
        {hideStats ? (
          <>
            <div className={styles.topRow}>
              <div className={styles.statsTitle} ref={ref}>
                <Typography variant="h6">Chipsat Stats</Typography>
                <FormControl variant="filled">
                  <Select
                    value={selectedID}
                    onChange={(e) => setSelectedID(e.target.value)}
                    inputProps={{ className: styles.select }}
                    MenuProps={{
                      container: ref.current,
                    }}
                  >
                    {Object.keys(sparks).map((id) => (
                      <MenuItem key={id} value={id}>
                        {config ? config[id].name : id}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <TableContainer>
                <Table sx={{}} size="small">
                  <TableHead>
                    <TableRow>
                      {STATS_CONFIG.topRow.map((row) => (
                        <TableCell key={row.name}>{row.name}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      {STATS_CONFIG.topRow.map((row) => (
                        <TableCell key={row.name}>
                          {row.getValue(sparks[selectedID])}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {STATS_CONFIG.middleRow.map((row) => (
                      <TableCell key={row.name}>{row.name}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {STATS_CONFIG.middleRow.map((row) => (
                      <TableCell key={row.name}>
                        {row.getValue(sparks[selectedID])}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {STATS_CONFIG.bottomRow.map((row) => (
                      <TableCell key={row.name}>{row.name}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {STATS_CONFIG.bottomRow.map((row) => (
                      <TableCell key={row.name}>
                        {row.getValue(sparks[selectedID])}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <div className={styles.placeholderContainer}>
            <Typography variant="h6">ChipSat stats</Typography>
          </div>
        )}
      </Paper>
    </FullscreenWrapper>
  );
}
