import { convertDdToDms, dmsString, splitCoordinates } from './geoHelpers.js';

const urlParams = new URLSearchParams(window.location.search);

const backendPort = process.env.REACT_APP_API_URL.split(':')[2] || urlParams.get('wsport') || 9090;
export const localSync =
  urlParams.get('nows') === '1' || urlParams.get('nows') === 'true';

export const connectionSecure =
  urlParams.get('wssecure') || window.location.protocol === 'https:';

const backendHost = window.location.hostname;

export const wsURL = `${
  connectionSecure ? 'wss' : 'ws'
}://${backendHost}:${backendPort}`;
export const backendURL = `${
  connectionSecure ? 'https' : 'http'
}://${backendHost}:${backendPort}} `;

export const LEADERBOARD_METRICS = [
  {
    name: 'Highest',
    getValue: (data) => `${data.Altitude_MSL_ft.toFixed()} ft`,
    sort: (dataA, dataB) => dataB.Altitude_MSL_ft - dataA.Altitude_MSL_ft,
  },
  {
    name: 'Furthest from drop point',
    getValue: (data) => `${data?.lateral_distance.toFixed()} ft`,
    sort: (dataA, dataB) => dataB.lateral_distance - dataA.lateral_distance,
  },
  {
    name: 'Highest Velocity',
    getValue: (data) => `${data.Velocity_mph.toFixed()} mph`,
    sort: (dataA, dataB) => dataB.Velocity_mph - dataA.Velocity_mph,
  },
];

export const CHART_VARIABLES = [
  {
    getValue: (data) => data['ts'],
    name: 'Time',
    transform: (value) => {
      const time = new Date(value);
      return `${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}`;
    },
    disableYAxis: true,
  },
  {
    getValue: (data) => data['Altitude_MSL_ft'],
    name: 'Altitude',
    transform: (value) => `${value?.toFixed()} ft`,
  },
  {
    getValue: (data) => data['Latitude_deg'],
    name: 'Latitude',
    transform: (value) =>
      splitCoordinates(dmsString(convertDdToDms(value, false))),
  },
  {
    getValue: (data) => data['Longitude_deg'],
    name: 'Longitude',
    transform: (value) =>
      splitCoordinates(dmsString(convertDdToDms(value, true))),
  },
  {
    getValue: (data) => data['Velocity_mph'],
    name: 'Velocity',
    transform: (value) => `${value?.toFixed()} mph`,
  },
  {
    getValue: (data) => data['Acceleration_g'],
    name: 'Acceleration',
    transform: (value) => `${value?.toFixed(2)} g`,
  },
  {
    getValue: (data) => data['Temperature_F'],
    name: 'Temperature (F)',
    transform: (value) => `${value?.toFixed()}° F`,
  },
  {
    getValue: (data) => data['Pressure_atm'],
    name: 'Pressure',
    transform: (value) => `${value?.toFixed(3)} atm`,
  },
  {
    getValue: (data) => data['RH_percent'],
    name: 'Relative humidity',
    transform: (value) => `${value?.toFixed()}%`,
  },
];

export const STATS_CONFIG = {
  topRow: [
    {
      name: 'Lateral distance',
      getValue: (spark) =>
        spark && spark?.data?.lateral_distance.toFixed() + ' ft',
    },
  ],
  middleRow: [
    {
      name: 'Latitude',
      getValue: (spark) =>
        dmsString(convertDdToDms(spark?.data?.Latitude_deg, false)),
    },
    {
      name: 'Longitude',
      getValue: (spark) =>
        dmsString(convertDdToDms(spark?.data?.Longitude_deg, true)),
    },
    {
      name: 'Altitude',
      getValue: (spark) => spark?.data?.Altitude_MSL_ft.toFixed() + ' ft',
    },
    {
      name: 'Velocity',
      getValue: (spark) => spark?.data?.Velocity_mph.toFixed(1) + ' mph',
    },
  ],
  bottomRow: [
    {
      name: 'Temperature',
      getValue: (spark) => spark?.data?.Temperature_F.toFixed(1) + ' °F',
    },
    {
      name: 'Pressure',
      getValue: (spark) => spark?.data?.Pressure_atm.toFixed(3) + ' atm',
    },
    {
      name: 'Humidity',
      getValue: (spark) => spark?.data?.RH_percent.toFixed() + '%',
    },
    {
      name: 'Acceleration',
      getValue: (spark) => spark?.data?.Acceleration_g.toFixed(2) + ' g',
    },
  ],
};

export const chipSatConfigs = {
  '01': {
    name: 'Jeffrey',
    borderColor: 'rgb(8, 178, 227)',
    backgroundColor: 'rgba(8, 178, 227, 0.5)',
  },
  '03': {
    name: 'Grace',
    borderColor: 'rgb(128, 26, 134)',
    backgroundColor: 'rgba(128, 26, 134, 0.5)',
  },
  '05': {
    name: 'Caren',
    borderColor: 'rgb(229, 178, 93)',
    backgroundColor: 'rgba(229, 178, 93, 0.5)',
  },
  '07': {
    name: 'Bob',
    borderColor: 'rgb(143, 227, 136)',
    backgroundColor: 'rgb(143, 227, 136, 0.5)',
  },
  '09': {
    name: 'Einstein',
    borderColor: 'rgb(241, 81, 82)',
    backgroundColor: 'rgb(241, 81, 82, 0.5)',
  },
};

export const getChipSatName = (id) => {
  return chipSatConfigs[id].name || id;
};

export const defaults = {
  stats: '05',
  leaderboardMetric: LEADERBOARD_METRICS[0],
};
