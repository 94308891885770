import XRegExp from 'xregexp';

// Found at https://www.movable-type.co.uk/scripts/latlong.html
export function getDistanceInMeters(lat1, lon1, lat2, lon2) {
  const R = 6371e3; // metres
  const φ1 = (lat1 * Math.PI) / 180; // φ, λ in radians
  const φ2 = (lat2 * Math.PI) / 180;
  const Δφ = ((lat2 - lat1) * Math.PI) / 180;
  const Δλ = ((lon2 - lon1) * Math.PI) / 180;

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c; // in metres
}

// Found at https://stackoverflow.com/a/5786281/7355242
export function convertDdToDms(D, lng) {
  return {
    dir: D < 0 ? (lng ? 'W' : 'S') : lng ? 'E' : 'N',
    d: 0 | (D < 0 ? (D = -D) : D),
    m: 0 | (((D += 1e-9) % 1) * 60),
    s: (0 | (((D * 60) % 1) * 6000)) / 100,
  };
}

export function dmsString(dms) {
  return `${dms.d}°${dms.m}′${dms.s.toFixed(2)}″${dms.dir}`;
}

export function splitCoordinates(coordString) {
  const s = XRegExp.split(coordString, /′/g);
  s[0] = `${s[0]}′`;
  return s;
}